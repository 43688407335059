import React, {useEffect} from 'react';
import './strategy.scss'
import MyButton from '../../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../../components/UI/title/CustomTitle';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

const Strategy1 = ({setButtonChoose, setButtonShow, setButtonText}) => {
    useEffect(() => {
        setButtonChoose(false);
        setButtonShow(true);
        setButtonText('Continue');
    }, []);
    return (
        <div style={{paddingBottom: "12rem" }}>
            <CustomTitle style={{ marginBottom: "24rem" }} title="Your personal creative strategy is almost ready!" />
            <div style={{ aspectRatio: "303/274", margin: "0 0rem 0rem" }}>
                <Player

                    autoplay
                    loop
                    src={process.env.PUBLIC_URL + '/data/strategy-1.json'}

                    
                >
                    {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                </Player>
            </div>

            {/* <div className="strategy-block">
                <div className="strategy-top">
                    <div className="strategy-top-item">
                        <div className="strategy-top-item__img">
                            <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + '/img/s1-1.jpg'} alt="" />
                        </div>
                        <span>Brand</span>
                    </div>
                    <div className="strategy-top-item">
                        <div className="strategy-top-item__img">
                            1080x1080
                        </div>
                        <span>Size</span>
                    </div>
                    <div className="strategy-top-item">
                        <div className="strategy-top-item__img">
                            <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + '/img/s1-2.jpg'} alt="" />
                        </div>
                        <span>Image</span>
                    </div>
                </div>
                <div className="strategy-img">
                    <div style={{ aspectRatio: "23/12", margin: "0 0rem 0rem" }}>
                        <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + '/img/s1-3.png'} alt="" />
                    </div>
                </div>
            </div> */}
{/* 
            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <>


                        <MyButton onClick={next} buttonText={'Continue'}>

                        </MyButton >


                    </>
                )}
            /> */}
        </div>
    );
}

export default Strategy1;
