import React, { useEffect, useState } from 'react'
import './style.scss'

export default function Timer({text}) {
    const targetDate = new Date('November 29, 2024 23:59:00').getTime();
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    function calculateTimeLeft() {
        const now = new Date().getTime();
        const difference = targetDate - now;

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
    }, []);



    return (
        <div className="home-timer">
            <div className="home-timer-wrapper">
                <span>
            

                    {timeLeft.days}
                    <b>days</b>
                </span>
                <i>:</i>
                <span>
         
                    {timeLeft.hours}
                    <b>hours</b>
                </span>
                <i>:</i>
                <span>
      
                    {timeLeft.minutes}
                    <b>mins</b>
                </span>
                <i>:</i>
                <span>

                    {timeLeft.seconds}
                    <b>secs</b>
                </span>
            </div>
            <p dangerouslySetInnerHTML={{ __html: text || 'Hurry up! <br />Limited-time deal.' }}></p>
        </div>
    )
}
