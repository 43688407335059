import React, { useEffect, useState } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './benefits.scss'
import axios from 'axios';
import MyButton from '../../components/UI/button/MyButton';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useOverflow from '../../hooks/useOverflow';
import useBlockClassName from '../../hooks/useBlockClassName';



const Benefits = ({ setButtonShow, setEventProperties, sessionId, setEventName, setWhiteWrapper }) => {
    const history = useHistory();
    const { changeOverflow } = useOverflow();
    const { updateBlockClassName } = useBlockClassName();
    useEffect(() => {
        updateBlockClassName("")
        changeOverflow('hidden')
        setButtonShow(false);
        setEventName('web_comparison')
        setEventProperties({
            web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
            session_id: sessionId,
            user_id: localStorage.getItem('userId')
        })
        setWhiteWrapper(false)
        localStorage.setItem('PriceId', 'price?version=1')
    }, []);
    const [pricePage, setPricePage] = useState('price?version=1');

    useEffect(() => {
        axios.get("https://staging.zeely.link/pages/paywalls", {
            headers: {
                "Content-Type": "application/json",
            },
            redirect: 'follow'
        })
            .then(response => {
                // localStorage.setItem('HomeTitle', response.data.data.title)
                // localStorage.setItem('HomeText', response.data.data.text)
                localStorage.setItem('PriceId', response.data.data.pageId)
                setPricePage(response.data.data.pageId)
                // history.push(response.data.data.pageId);
            })
            .catch(error => {
                console.log(error)

            });
    }, []);


    return (
        <div >
            <CustomTitle style={{ marginBottom: "24rem" }} title="Become the leader in consumer attention!" />

            <div className="benefits">
                <div className="benefits-item">
                    <div className="benefits-item__title">Without AI</div>
                    <div style={{ aspectRatio: "149/162" }}>
                        <img src={process.env.PUBLIC_URL + '/img/benefits1.png'} style={{ borderRadius: '12rem' }} alt="" />
                    </div>
                    <ul>
                        <li>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8.5" cy="8.5" r="8" fill="#EF2C4F" />
                                <path d="M5.6983 5.6983C5.84031 5.55629 6.07055 5.55629 6.21256 5.6983L8.50089 7.98634L10.7892 5.6983C10.9203 5.56722 11.1266 5.55713 11.2692 5.66805L11.3035 5.6983C11.4455 5.84031 11.4455 6.07055 11.3035 6.21256L9.01543 8.50089L11.3035 10.7892C11.4346 10.9203 11.4446 11.1266 11.3337 11.2692L11.3035 11.3035C11.1615 11.4455 10.9312 11.4455 10.7892 11.3035L8.50089 9.01543L6.21256 11.3035C6.08148 11.4346 5.87521 11.4446 5.73256 11.3337L5.6983 11.3035C5.55629 11.1615 5.55629 10.9312 5.6983 10.7892L7.98634 8.50089L5.6983 6.21256C5.56722 6.08148 5.55713 5.87521 5.66805 5.73256L5.6983 5.6983Z" fill="white" stroke="white" stroke-width="0.363636" />
                            </svg>

                            Expensive Ads
                        </li>
                        <li>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8.5" cy="8.5" r="8" fill="#EF2C4F" />
                                <path d="M5.6983 5.6983C5.84031 5.55629 6.07055 5.55629 6.21256 5.6983L8.50089 7.98634L10.7892 5.6983C10.9203 5.56722 11.1266 5.55713 11.2692 5.66805L11.3035 5.6983C11.4455 5.84031 11.4455 6.07055 11.3035 6.21256L9.01543 8.50089L11.3035 10.7892C11.4346 10.9203 11.4446 11.1266 11.3337 11.2692L11.3035 11.3035C11.1615 11.4455 10.9312 11.4455 10.7892 11.3035L8.50089 9.01543L6.21256 11.3035C6.08148 11.4346 5.87521 11.4446 5.73256 11.3337L5.6983 11.3035C5.55629 11.1615 5.55629 10.9312 5.6983 10.7892L7.98634 8.50089L5.6983 6.21256C5.56722 6.08148 5.55713 5.87521 5.66805 5.73256L5.6983 5.6983Z" fill="white" stroke="white" stroke-width="0.363636" />
                            </svg>

                            Manual Effort
                        </li>
                        <li>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8.5" cy="8.5" r="8" fill="#EF2C4F" />
                                <path d="M5.6983 5.6983C5.84031 5.55629 6.07055 5.55629 6.21256 5.6983L8.50089 7.98634L10.7892 5.6983C10.9203 5.56722 11.1266 5.55713 11.2692 5.66805L11.3035 5.6983C11.4455 5.84031 11.4455 6.07055 11.3035 6.21256L9.01543 8.50089L11.3035 10.7892C11.4346 10.9203 11.4446 11.1266 11.3337 11.2692L11.3035 11.3035C11.1615 11.4455 10.9312 11.4455 10.7892 11.3035L8.50089 9.01543L6.21256 11.3035C6.08148 11.4346 5.87521 11.4446 5.73256 11.3337L5.6983 11.3035C5.55629 11.1615 5.55629 10.9312 5.6983 10.7892L7.98634 8.50089L5.6983 6.21256C5.56722 6.08148 5.55713 5.87521 5.66805 5.73256L5.6983 5.6983Z" fill="white" stroke="white" stroke-width="0.363636" />
                            </svg>

                            Time Consuming
                        </li>

                    </ul>
                </div>
                <div className="benefits-item">
                    <div className="benefits-item__title">With AI</div>
                    <div style={{ aspectRatio: "149/162" }}>
                        <img src={process.env.PUBLIC_URL + '/img/benefits2.png'} style={{ borderRadius: '12rem' }} alt="" />
                    </div>
                    <ul>
                        <li>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8.5" cy="8.5" r="8" fill="#5BF0A5" />
                                <path d="M7.40971 10.1681L5.48502 8.2434C5.34301 8.10139 5.11277 8.10139 4.97076 8.2434C4.82876 8.38541 4.82876 8.61565 4.97076 8.75766L7.15258 10.9395C7.29459 11.0815 7.52483 11.0815 7.66684 10.9395L12.0305 6.57584C12.1725 6.43384 12.1725 6.20359 12.0305 6.06158C11.8885 5.91958 11.6582 5.91958 11.5162 6.06158L7.40971 10.1681Z" fill="black" stroke="black" stroke-width="0.363636" />
                            </svg>

                            Profitable Ads
                        </li>
                        <li>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8.5" cy="8.5" r="8" fill="#5BF0A5" />
                                <path d="M7.40971 10.1681L5.48502 8.2434C5.34301 8.10139 5.11277 8.10139 4.97076 8.2434C4.82876 8.38541 4.82876 8.61565 4.97076 8.75766L7.15258 10.9395C7.29459 11.0815 7.52483 11.0815 7.66684 10.9395L12.0305 6.57584C12.1725 6.43384 12.1725 6.20359 12.0305 6.06158C11.8885 5.91958 11.6582 5.91958 11.5162 6.06158L7.40971 10.1681Z" fill="black" stroke="black" stroke-width="0.363636" />
                            </svg>

                            100% Automated
                        </li>
                        <li>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8.5" cy="8.5" r="8" fill="#5BF0A5" />
                                <path d="M7.40971 10.1681L5.48502 8.2434C5.34301 8.10139 5.11277 8.10139 4.97076 8.2434C4.82876 8.38541 4.82876 8.61565 4.97076 8.75766L7.15258 10.9395C7.29459 11.0815 7.52483 11.0815 7.66684 10.9395L12.0305 6.57584C12.1725 6.43384 12.1725 6.20359 12.0305 6.06158C11.8885 5.91958 11.6582 5.91958 11.5162 6.06158L7.40971 10.1681Z" fill="black" stroke="black" stroke-width="0.363636" />
                            </svg>

                            {`Results in <5 mins`}
                        </li>

                    </ul>
                </div>

            </div>
            <MyButton style={{ marginTop: '32rem' }} buttonText={"Continue"} onClick={() => {
                 mixpanel.track('web_comparison', {
                    session_id: sessionId,
                    web_entrance: localStorage.getItem('signupMethod') === "Login" ? 'login' : 'signup',
                    user_id: localStorage.getItem('userId'),
                    // web_pay_method: 'CreditCard',
                    web_utm_campaign: localStorage.getItem("utmCampaign"),
                    web_utm_source: localStorage.getItem("utmSource"),
                    web_utm_medium: localStorage.getItem("utmMedium"),
                    web_utm_content: localStorage.getItem("utmContent"),
                    web_utm_term: localStorage.getItem("utmTerm"),
                    isDebug: false,
                    funnel: 'MAIN_v1',
                });
                history.push('/price')
                // history.push(pricePage)
            }
            } />
        </div>
    );
}

export default Benefits;
